<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.assignments.list' }">
                Atamalar
              </router-link>
            </li>
            <li>Atama Düzenle</li>
          </ul>
        </nav>
      </div>
      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>Atama Düzenle</span></h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">
        <ul class="uk-child-width-expand uk-tab stepper-buttons" id="main-switcher"
          uk-switcher="connect: #main-switcher-tabs ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <!-- <li>
            <a class="p-4" href="#"
              ><i class="icon-feather-flag"></i> <b>{{this.$t('messages.login')}}</b></a
            >
          </li>
          <li>
            <a class="p-4" href="#"
              ><i class="uil-book-open"></i> <b>{{this.$t('messages.content')}}</b></a
            >
          </li>
          <li>
            <a class="p-4" href="#"
              ><i class="uil-users-alt"></i> <b>Kullanıcılar</b></a
            >
          </li>
          <li>
            <a class="p-4" href="#"><i class="uil-gift"></i> <b>Hediye</b></a>
          </li>
          <li>
            <a class="p-4" href="#"><i class="uil-cog"></i> <b>Ayarlar</b></a>
          </li> -->
          <li class="uk-active">
            <a class="p-4" href="#"><i class="uil-invoice"></i> <b>Sonuç</b></a>
          </li>
        </ul>

        <default-loading v-if="assignment_loading || !formattedAssignmentItem"></default-loading>
        <template v-else>
          <div class="card-body" v-if="item_loaded">
            <ul class="uk-switcher uk-margin" id="main-switcher-tabs">
              <li>
                <div class="row">
                  <div class="col-12 my-lg-5">
                    <div class="text-center">
                      <div class="col-md-6 offset-md-3">
                        <table class="uk-table uk-table-divider result-table">
                          <tbody>
                            <tr>
                              <td class="text-center" colspan="2">
                                <img class="logoarea rounded img-fluid" :src="cover_image_link
                                  ? cover_image_link
                                  : '@/assets/images/no-photo.png'
                                  " alt="" />
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">Atama Adı:</td>
                              <td class="text-left">
                                <b>{{ name }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">
                                {{ this.$t('general.number_of_assigned_users') }}:
                              </td>
                              <td class="text-left">
                                <b>{{ checked_users.length }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">{{ this.$t('general.give_a_gift_at_the_end') }}:</td>
                              <td class="text-left">
                                <b>{{
                                  assignment_item.has_present
                                  ? $t("general.yes")
                                  : $t("general.no")
                                }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">
                                {{ this.$t('general.top_rankings_list_display') }}:
                              </td>
                              <td class="text-left">
                                <b>{{
                                  appear_top
                                  ? $t("general.yes")
                                  : $t("general.no")
                                }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">{{ this.$t('general.start_day') }}:</td>
                              <td class="text-left">
                                <b>{{ start_date }}</b>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-right">Son Tamamlama Tarihi:</td>
                              <td class="text-left">
                                <b>{{ end_date }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-if="qrCodeCanBeShown">
                          <QrCodeModal :modal_title="qor_code_modal_title" :modal_name="'qr_code_for_classroom_education'"
                            :hideSubmit="true" :qr_text="qr_text" :showCloseBtn="false"></QrCodeModal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";
import UserGroupSelection from "@/view/components/assignment/UserGroupSelection";
import Content from "@//view/components/assignment/Content";
import Gift from "@//view/components/assignment/Gift";
import HtmlArea from "@/view/company/Mail/Html";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

import assignment_module, {
  BASE_URL as ASSIGNMENT_BASE_URL,
  ERROR as ASSIGNMENT_ERROR,
  GET_ITEM as ASSIGNMENT_GET_ITEM,
  ITEM as ASSIGNMENT_ITEM,
  LOADING as ASSIGNMENT_LOADING,
  MODULE_NAME as ASSIGNMENT_MODULE_NAME,
  UPDATE_ITEM as ASSIGNMENT_UPDATE_ITEM,
} from "@/core/services/store/assignment.module";

import survey_module, {
  BASE_URL as SURVEY_BASE_URL,
  GET_ITEMS as SURVEY_GET_ITEMS,
  ITEMS as SURVEY_ITEMS,
  MODULE_NAME as SURVEY_MODULE_NAME,
} from "@/core/services/store/evaluation_survey.module";

import { GET_ITEM_DETAIL_BY_ID } from "@/core/services/store/REST.module";
import QrCodeModal from "@/view/components/assignment/QrCodeModal.vue";
const _SURVEY_MODULE = SURVEY_MODULE_NAME;
const _ASSIGNMENT_MODULE = ASSIGNMENT_MODULE_NAME;

export default {
  name: "AddAssignment",
  components: {
    UserGroupSelection,
    draggable,
    Content,
    Gift,
    HtmlArea,
    DefaultLoading,
    QrCodeModal
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_SURVEY_MODULE, survey_module);
    registerStoreModule(_ASSIGNMENT_MODULE, assignment_module);
  },
  data() {
    return {
      qrCodeCanBeShown: false,
      qor_code_modal_title: "Sınıf İçi Eğitim QR Kodu",
      qr_text: null,
      restUrl: "api/mail-template/assignment",
      successMessage: null,
      errorMessages: [],
      cover_image: null,
      cover_image_link: null,
      checked_users: [],
      checked_groups: [],
      added_users: [],
      added_groups: [],
      name: null,
      is_compulsory: 0,
      start_date: null,
      end_date: null,
      repeatable: true,
      repetition_no: 0,
      duration: 0,
      survey_id: null,
      appear_top: false,
      survey_required: true,
      highlight_education: false,
      has_feedbacks: false,
      description: null,
      gift_rule_id: 1,
      active_content_id: null,
      gifts: [],
      educations: [],
      has_customized_template: false,
      mail_template: [],
      mail_template_codes: [],
      company: [],
      all_selected_educations: [],
      all_selected_gifts: [],
      item_loaded: false,
    };
  },
  computed: {
    assignment_item: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ITEM];
      },
      set(value) { },
    },
    assignment_loading: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_LOADING];
      },
      set(value) { },
    },
    formattedAssignmentItem() {
      let self = this;
      let item = self.assignment_item;

      self.item_loaded = false;
      if (item == null || item.users == undefined) return false;
      self.checked_users = item.users;
      self.added_users = item.users;
      self.name = item.name;
      self.description = item.description;
      self.is_compulsory = item.is_compulsory;
      self.start_date = item.start_date;
      self.end_date = item.end_date;
      self.repeatable = item.repeatable;
      self.duration = item.duration;
      self.repetition_no = item.repetition_no;
      self.survey_id = item.evaluation_survey_id;
      self.appear_top = item.appear_top;
      self.has_customized_template = item.has_customized_template;
      if (item.has_customized_template && self.mail_template.html != undefined)
        self.mail_template.html = item.mail_template;
      self.has_feedbacks = item.has_feedbacks == true ? true : false;
      self.survey_required = item.survey_required;
      self.highlight_education = item.highlight_education;
      self.gift_rule_id = item.gift_rule_id;
      self.gifts = item.gifts;
      self.educations = item.educations;
      self.all_selected_educations = item.all_selected_educations;
      self.all_selected_gifts = item.all_selected_gifts;
      self.cover_image_link = item.cover_image_link;
      self.item_loaded = true;
      return true;
    },
    survey_items: {
      get() {
        return store.getters[SURVEY_MODULE_NAME + "/" + SURVEY_ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    getMailTemplateData() {
      let self = this;
      self.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: self.restUrl,
          acceptPromise: false,
        })
        .then((result) => {
          if (result.status) {
            self.company = result.data.company;
            self.mail_template = result.data.template;
            self.mail_template_codes = result.data.codes;
          }
        });
    },
    saveForm() {
      let self = this;
      if (!this.isValidFields("ALL")) return false;
      if (self.duration == "" || self.duration < 0) self.duration = 0;

      let formData = new FormData();
      formData.append("name", self.name);
      formData.append("description", self.description);
      formData.append(
        "has_present",
        self.assignment_item.has_present == true ? 1 : 0
      );
      formData.append("gift_rule_id", self.gift_rule_id);
      self.gifts.forEach((element) => {
        formData.append("gifts[]", element.id);
      });
      self.educations.forEach((element, index) => {
        formData.append("contents[" + index + "]", element.id);
      });
      self.checked_users.forEach((element) => {
        formData.append("users[]", element);
      });
      formData.append("is_compulsory", self.is_compulsory == true ? 1 : 0);
      formData.append("start_date", self.start_date);
      formData.append("end_date", self.end_date);

      formData.append(
        "highlight_education",
        self.highlight_education == true ? 1 : 0
      );

      formData.append("duration", self.duration);

      formData.append("survey_required", self.survey_required == true ? 1 : 0);
      if (self.survey_required == true)
        formData.append("evaluation_survey_id", self.survey_id);

      formData.append(
        "has_customized_template",
        self.has_customized_template == true ? 1 : 0
      );
      if (self.has_customized_template == true)
        formData.append("mail_template", self.mail_template.html);

      formData.append("has_feedbacks", self.has_feedbacks == true ? 1 : 0);
      formData.append("appear_top", self.appear_top == true ? 1 : 0);
      if (self.cover_image) formData.append("cover_image", self.cover_image);

      store
        .dispatch(ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_UPDATE_ITEM, {
          url: ASSIGNMENT_BASE_URL + "/update/" + this.$route.params.id,
          contents: formData,
        })
        .then(() => {
          self.scrollToTop();
          if (!this.error) {
            this.successMessage = self.$t("general.successfully_updated");
            this.$router.push({ name: "company.assignments.list" });
          } else this.errorMessages.push(this.error);
        });
    },
    updateMailTemplate(newCode) {
      if (
        this.mail_template.is_linked &&
        (!newCode.includes("{SLINK}") || !newCode.includes("<!--BUTTON-->"))
      ) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(":NAME", "{SLINK}")
        );
        return false;
      }
      this.mail_template.html = newCode;
      if (this.assignment_item.mail_template != undefined && newCode)
        this.assignment_item.mail_template = newCode;
    },
    codeSlice() {
      if (this.mail_template_codes.length == undefined) return 0;
      return Math.ceil(this.mail_template_codes.length / 2);
    },
    copyCode(code) {
      const el = document.createElement("textarea");
      el.value = code;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    addContentToAssignment(item) {
      let edu_id = item.id;
      if (this.all_selected_educations.includes(edu_id)) return false;
      this.educations.push(item);
      this.all_selected_educations.push(edu_id);
    },
    addGiftToContent(item) {
      let gift_id = item.id;
      this.all_selected_gifts.push(gift_id);
      this.gifts.push(item);
    },
    removeGiftFromContent(id) {
      let gifts = this.gifts;
      let gifts_ids_all = this.all_selected_gifts;
      let pos = gifts
        .map(function (x) {
          return x;
        })
        .indexOf(id);
      let pos_all = gifts_ids_all
        .map(function (x) {
          return x;
        })
        .indexOf(id);
      gifts.splice(pos, 1);
      gifts_ids_all.splice(pos_all, 1);
      return true;
    },
    removeContentFromAssignment(edu_id) {
      let educations = this.educations;
      let educations_ids_all = this.all_selected_educations;

      let pos_all = educations_ids_all.map((x) => x).indexOf(edu_id);

      let edu_pos = educations.map((x) => x.id).indexOf(edu_id);

      educations.splice(edu_pos, 1);
      educations_ids_all.splice(pos_all, 1);

      return true;
    },
    getAssignmentItem() {
      this.$store.dispatch(ASSIGNMENT_MODULE_NAME + "/" + ASSIGNMENT_GET_ITEM, {
        url: ASSIGNMENT_BASE_URL + "/" + this.$route.params.id,
      }).then((response) => {
        const containsAnyClassRoomEducation = response.educations.some((education) => education.name === 'documents.classroom_education');
        if (containsAnyClassRoomEducation) {
          this.qrCodeCanBeShown = true;
          this.qr_text = `${window.location.origin}/#/assignments/${this.$route.params.id}/classroom-education`;
          return;
        }
      });
    },
    getSurveys() {
      this.$store.dispatch(SURVEY_MODULE_NAME + "/" + SURVEY_GET_ITEMS, {
        url: SURVEY_BASE_URL,
        filters: {
          status_id: 1,
          order: "asc",
          sort: "id",
          fields: "id,name",
        },
      });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getResultsAll() {
      this.getMailTemplateData();
      this.getAssignmentItem();
      this.getSurveys();
    },
    processFile(event) {
      this.cover_image = event.target.files[0];
      let blob = URL.createObjectURL(this.cover_image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#cover_photo_input").click();
    },
    isValidFields(fields) {
      let self = this;
      self.resetMessages();
      if (fields != "ALL") fields--;

      if (fields == "ALL" || fields == 0) {
        if (self.name == null || self.name.length == 0) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Atama Adı")
          );
        }
        if (self.description == null || self.description.length == 0) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Açıklama")
          );
        }
      }
      if (
        (fields == "ALL" || fields == 1) &&
        self.all_selected_educations.length == 0
      ) {
        return self.showFieldsFailedError(
          1,
          self.$t("validation.required_field").replace(":NAME", "{{this.$t('messages.content')}}")
        );
      }
      if (
        (fields == "ALL" || fields == 2) &&
        self.checked_users.length == 0 &&
        self.checked_groups.length == 0
      ) {
        return self.showFieldsFailedError(
          2,
          self.$t("validation.required_field").replace(":NAME", "Kullanıcılar")
        );
      }
      if (
        (fields == "ALL" || fields == 3) &&
        self.assignment_item.has_present &&
        self.all_selected_gifts.length == 0
      ) {
        return self.showFieldsFailedError(
          3,
          self.$t("validation.required_field").replace(":NAME", "Hediye")
        );
      }
      if (fields == "ALL" || fields == 4) {
        if (self.start_date == null) {
          return self.showFieldsFailedError(
            4,
            self
              .$t("validation.required_field")
              .replace(":NAME", "Başlangıç Tarihi")
          );
        }

        if (self.end_date == null) {
          return self.showFieldsFailedError(
            4,
            self
              .$t("validation.required_field")
              .replace(":NAME", "Son Tamamlama Tarihi")
          );
        }

        // if (self.repeatable && self.repetition_no <= 0) {
        //   return self.showFieldsFailedError(4, self.$t("validation.required_field").replace(":NAME", "Tekrar Sayısı"));
        // }

        if (self.survey_required)
          if (self.survey_id == null || !self.survey_id) {
            return self.showFieldsFailedError(
              4,
              self.$t("validation.required_field").replace(":NAME", "Anketi")
            );
          }

        if (self.has_customized_template)
          if (
            self.mail_template.html == null ||
            self.mail_template.html == ""
          ) {
            return self.showFieldsFailedError(
              4,
              self
                .$t("validation.required_field")
                .replace(":NAME", "E-posta Şablonunu")
            );
          }
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {
      if (this.isValidFields(index)) this.switcherChange(index);
    },
    switcherChange(index) {
      UIkit.switcher($("#main-switcher")[0]).show(index);
    },
    setMinimumDate(start_date = null) {
      let date = new Date();
      if (start_date) {
        let startDate = new Date(start_date);
        date = new Date(startDate.getTime() + 86400000); // + 1 day in ms
      }
      return date.toISOString().split("T")[0];
    },
  },
  mounted() {
    this.getResultsAll();
    setTimeout(() => {
      $(".selectpicker7").selectpicker();
    }, 200);

  },
  watch: {
    survey_items(value) {
      if (value && value.length)
        setTimeout(() => {
          $(".selectpicker_survey").selectpicker();
        }, 500);
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-tab li.uk-active a {
  border-color: #eceff0;
}

.uk-tab-right::before {
  display: none;
}

.sidemenu li a {
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}
</style>
